<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tbData"
      :single-expand="singleExpand"
      :search="search"
      :loading="loadingTb"
      loading-text="Sedang Memuat Data..."
      disable-pagination
      hide-default-footer
      :expanded.sync="expanded"
      item-key="id"
    >

      <template v-slot:item.noRow="{ item }">
        {{tbData.map(function(x) {return x.registerId; }).indexOf(item.registerId)+1}}
      </template>

      <template v-slot:item.kdakuns="{item}">
        <div class="p-2">
          {{item.kdakuns.join(', ')}}
        </div>
      </template>
      <template v-slot:item.statusKinerja="{item}">
        <div class="p-2">
          <v-chip small
            class="ma-2"
            :color="item.color"
            text-color="white"
          >
            {{item.statusKinerja}}
          </v-chip>
        </div>
      </template>

      <template v-slot:item.indexKerja="{item}">
        <div class="p-2">
          {{item.indexKerja}}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu
          transition="slide-x-transition"
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#fafafa"
              class="btn-table"
              fab
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list
            flat
            dense
            elevation="1"
          >
            <v-list-item-group color="primary">
              <v-list-item @click="detail(item.registerId)">
                <v-list-item-icon class="mr-3">
                  <v-icon small>mdi-clipboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Detail</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

  </div>
</template>
<script>
export default {
  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        { text: "No", value: "noRow", width: "1%" },
        { text: "ID Register", value: "registerId" },
        { text: "Nama Register", value: "registerNm" },
        { text: "Tanggal Efektif", value: "tanggalEfektif" },
        { text: "Total Bulan", value: "totalBulan" },
        { text: "Total Bulan Terpakai", value: "totalBulanTerpakai" },
        { text: "Total Bulan Terpakai %", value: "totalBulanTerpakaiPrs" },
        { text: "Total Pinjaman", value: "totalPinjaman" },
        { text: "Total Dipa", value: "totalDipa" },
        { text: "Progress Variant", value: "pv" },
        { text: "Status", value: "statusKinerja" },
        // { text: "Act", value: "actions", sortable: false, width: "2%" },
      ],
    };
  },
  mounted() {},
  methods: {
    getTable() {
      this.$emit("getTable");
    },
    edit(id) {
      this.$emit("edit", id);
    },
    detail(id) {
      this.$emit("detail", id);
    },
  },

  props: ["tbData", "search", "loadingTb"],
};
</script>